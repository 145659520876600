/*
IMPORTANT 
This will not update the vue router query object
Use this when you don't want vue router history
*/
const addQueryParameter = (name, value, context) => {
	if (getQueryParameter(name)) {
		removeQueryParameter(name, context);
	}
	const url = new URL(location.href);
	url.searchParams.append(name, value);
	let path = context.$route.path;
	if (url.searchParams.toString().length > 0) {
		path = path + "?" + url.searchParams;
	}
	window.history.replaceState({}, document.title, "" + path);
};
const removeQueryParameter = (name, context) => {
	const url = new URL(location.href);
	url.searchParams.delete(name);
	let path = context.$route.path;
	if (url.searchParams.toString().length > 0) {
		path = path + "?" + url.searchParams;
	}
	delete context.$route.query[name];
	window.history.replaceState({}, document.title, "" + path);
};
const getQueryParameter = (name) => {
	const url = new URL(location.href);
	return url.searchParams.get(name);
};
const addQueryParameterToHref = (href, name, value) => {
	if (isValidUrl(href)) {
		const url = new URL(href);
		url.searchParams.append(name, value);
		return url.href;
	}
	return "";
};
const isValidUrl = (urlString) => {
	try {
		return Boolean(new URL(urlString));
	} catch (e) {
		console.error(e, `Passed URL : ${urlString}`);
		return false;
	}
};
export default {
	addQueryParameter,
	removeQueryParameter,
	getQueryParameter,
	addQueryParameterToHref,
};
